import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/AboutPage.css';
import { FaUsers, FaPray, FaSun, FaChild, FaUserFriends, 
         FaMusic, FaVideo, FaHandHoldingHeart, FaHandsHelping, FaQuestionCircle } from 'react-icons/fa';

const AboutPage = () => {
  const aboutSquares = [
    { id: 'leadership', title: 'Our Leadership', icon: <FaUsers className="about-square-icon" />, path: '/about#leadership', isExternal: false },
    { id: 'sermons', title: 'Sermons', icon: <FaPray className="about-square-icon" />, path: 'https://www.youtube.com/@MOUNTCARMELPC/streams', isExternal: true },
    { id: 'rays', title: 'Rays of Sunshine', icon: <FaSun className="about-square-icon" />, path: '/about#rays', isExternal: false },
    { id: 'youth', title: 'Youth Dept.', icon: <FaChild className="about-square-icon" />, path: '/about#youth', isExternal: false },
    { id: 'mens-womens', title: 'Men\'s & Women\'s Ministries', icon: <FaUserFriends className="about-square-icon" />, path: '/about#mens-womens', isExternal: false },
    { id: 'music', title: 'Music', icon: <FaMusic className="about-square-icon" />, path: '/about#music', isExternal: false },
    { id: 'media', title: 'Media', icon: <FaVideo className="about-square-icon" />, path: '/about#media', isExternal: false },
    { id: 'autism', title: 'Autism Support', icon: <FaHandHoldingHeart className="about-square-icon" />, path: '/about#autism-support', isExternal: false },
    { id: 'usher', title: 'Usher Dept.', icon: <FaHandsHelping className="about-square-icon" />, path: '/about#usher', isExternal: false },
    { id: 'faqs', title: 'FAQs', icon: <FaQuestionCircle className="about-square-icon" />, path: '/about#faqs', isExternal: false },
  ];

  return (
    <div className="about-page">
      <div className="about-content">
        <h1>About Us</h1>
        <div className="about-squares-container">
          {aboutSquares.map((square) => (
            square.isExternal ? (
              <a href={square.path} key={square.id} className="about-square" target="_blank" rel="noopener noreferrer">
                {square.icon}
                <h2>{square.title}</h2>
              </a>
            ) : (
              <Link to={square.path} key={square.id} className="about-square">
                {square.icon}
                <h2>{square.title}</h2>
              </Link>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;