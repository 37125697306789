import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/ContactUsPage.css';
import { FaPrayingHands, FaBook, FaCross, FaQuestion, FaWater, FaCommentAlt, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const ContactUsPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    location: '',
    message: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
      'service_07c15as',
      'template_jb9m20i',
      formData,
      'qtYUZzZsCvkeDQCHh'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSubmitted(true);
      setLoading(false);
    })
    .catch((err) => {
      console.error('FAILED...', err);
      alert('Failed to send message.');
      setLoading(false);
    });
  };

  return (
    <div className="contact-page">
      <div className="page-header">
        <h1>We're here for you</h1>
        <p>Have a question, need support, or just want to connect? Let us know how we can help.</p>
      </div>

      <div className="contact-options">
        <div className="option-card">
          <div className="icon prayer-icon">
            <FaPrayingHands />
          </div>
          <h3>I have a prayer request</h3>
          <p>Tell us how we can pray for you.</p>
        </div>
        <div className="option-card">
          <div className="icon story-icon">
            <FaBook />
          </div>
          <h3>I'd like to share my story</h3>
          <p>Tell us how God is working in your life.</p>
        </div>
        <div className="option-card">
          <div className="icon faith-icon">
            <FaCross />
          </div>
          <h3>I just accepted Christ</h3>
          <p>Get support and resources for your faith journey.</p>
        </div>
        <div className="option-card">
          <div className="icon question-icon">
            <FaQuestion />
          </div>
          <h3>I have a question</h3>
          <p>Need something else? We're here for you.</p>
        </div>
        <div className="option-card">
          <div className="icon baptized-icon">
            <FaWater />
          </div>
          <h3>I'd like to get baptized</h3>
          <p>Learn more about baptism and how to sign up.</p>
        </div>
        <div className="option-card">
          <div className="icon feedback-icon">
            <FaCommentAlt />
          </div>
          <h3>Website Feedback</h3>
          <p>Please share your feedback or report any issues.</p>
        </div>
      </div>

      <div className="prayer-form-section">
        <h2>Prayer Request</h2>
        <div className="form-container">
          <form onSubmit={handleSubmit} className={submitted ? 'form-submitted' : ''}>
            <div className="form-field-row">
              <div className="form-field">
                <label htmlFor="firstName">First Name *</label>
                <input 
                  type="text" 
                  id="firstName" 
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-field">
                <label htmlFor="lastName">Last Name *</label>
                <input 
                  type="text" 
                  id="lastName" 
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <div className="form-field">
              <label htmlFor="email">Email address *</label>
              <input 
                type="email" 
                id="email" 
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="confirmEmail">Confirm email address *</label>
              <input 
                type="email" 
                id="confirmEmail" 
                name="confirmEmail"
                required
              />
            </div>

            <div className="form-field">
              <label htmlFor="phone">Phone number *</label>
              <input 
                type="tel" 
                id="phone" 
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-field">
              <label htmlFor="message">Message *</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                required
              ></textarea>
            </div>

            <button type="submit" className={loading ? 'loading' : ''}>
              {loading ? 'Sending...' : 'Submit'}
            </button>

            {submitted && (
              <div className="success-message">
                <p>Your message has been sent successfully!</p>
              </div>
            )}
          </form>
        </div>
      </div>

      <div className="contact-info">
        <h2>Contact Info</h2>
        <div className="contact-details">
          <div className="contact-item">
            <div className="contact-icon address-icon">
              <FaMapMarkerAlt />
            </div>
            <div>
              <h3>Address</h3>
              <p>3080 White Plains Road<br/>Bronx, NY 10467</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon phone-icon">
              <FaPhone />
            </div>
            <div>
              <h3>Phone</h3>
              <p>(718) 231-9206</p>
            </div>
          </div>
          <div className="contact-item">
            <div className="contact-icon email-icon">
              <FaEnvelope />
            </div>
            <div>
              <h3>Email</h3>
              <p>info@mountcarmelpc.org</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsPage; 