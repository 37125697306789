import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/PrayerRequestPage.css';

const PrayerRequestPage = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    phone: '',
    prayerRequest: '',
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      prayerRequest: formData.prayerRequest
    };

    emailjs.send(
      'service_07c15as',
      'template_jb9m20i',
      templateParams,
      'qtYUZzZsCvkeDQCHh'
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      setSubmitted(true);
      setLoading(false);
    })
    .catch((err) => {
      console.error('FAILED...', err);
      alert('Failed to send prayer request.');
      setLoading(false);
    });
  };

  return (
    <div className="prayer-request-page">
      <div className="page-header">
        <h1>Prayer Request</h1>
        <p>How can we pray for you today?</p>
      </div>

      <div className="form-container">
        <form onSubmit={handleSubmit} className={submitted ? 'form-submitted' : ''}>
          <div className="form-field-row">
            <div className="form-field">
              <label htmlFor="firstName">First Name *</label>
              <input 
                type="text" 
                id="firstName" 
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-field">
              <label htmlFor="lastName">Last Name *</label>
              <input 
                type="text" 
                id="lastName" 
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="email">Email address *</label>
            <input 
              type="email" 
              id="email" 
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="confirmEmail">Confirm email address *</label>
            <input 
              type="email" 
              id="confirmEmail" 
              name="confirmEmail"
              value={formData.confirmEmail}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="phone">Phone number *</label>
            <input 
              type="tel" 
              id="phone" 
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-field">
            <label htmlFor="prayerRequest">How can we pray for you today? *</label>
            <textarea 
              id="prayerRequest" 
              name="prayerRequest"
              value={formData.prayerRequest}
              onChange={handleChange}
              rows="5"
              required
            ></textarea>
          </div>

          <button type="submit" className={loading ? 'loading' : ''}>
            {loading ? 'Sending...' : 'Submit'}
          </button>

          {submitted && (
            <div className="success-message">
              <p>Your prayer request has been submitted!</p>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PrayerRequestPage;
